<template>
  <v-app>
    <dashboard-app-bar
      v-if="this.$route.name !== 'Mapboard'"
      v-model="expandOnHover"
    />

    <dashboard-drawer
      v-if="this.$route.name !== 'Form build'"
      :expand-on-hover.sync="expandOnHover"
    />

    <dashboard-view />

    <!-- <dashboard-footer /> -->

    <!-- <dashboard-core-settings v-model="expandOnHover" /> -->
  </v-app>
</template>

<script>
  export default {
    name: 'DashboardIndex',

    components: {
      DashboardAppBar: () => import('@/layouts/dashboard/AppBar'),
      DashboardDrawer: () => import('@/layouts/dashboard/Drawer'),
      // DashboardCoreSettings: () => import('@/layouts/dashboard/Settings'),
      DashboardView: () => import('@/layouts/dashboard/View'),
      // DashboardFooter: () => import('@/layouts/dashboard/Footer'),
    },

    data: () => ({
      expandOnHover: false,
    }),
  }
</script>
